import React, { useState, useCallback, useRef } from 'react';
import Cropper from 'react-easy-crop';

const GeneratorCropper = ({ imageSrc, onCropComplete }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const previousCrop = useRef(crop);
  const previousZoom = useRef(zoom);

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const onZoomChange = (newZoom) => {
    setZoom(newZoom);
  };

  const onCropCompleteHandler = useCallback((croppedArea, croppedAreaPixels) => {
    if (previousCrop.current.x !== crop.x || previousCrop.current.y !== crop.y || previousZoom.current !== zoom) {
      previousCrop.current = crop;
      previousZoom.current = zoom;
      onCropComplete(croppedArea, croppedAreaPixels);
    }
  }, [crop, zoom, onCropComplete]);

  return (
    <div>
      <Cropper
        aspect={1 / 1}
        crop={crop}
        cropShape="round"
        image={imageSrc}
        maxZoom={10}
        onCropChange={onCropChange}
        onCropComplete={onCropCompleteHandler}
        onZoomChange={onZoomChange}
        showGrid={true}
        zoom={zoom}
      />
    </div>
  );
};

export default GeneratorCropper;
