import './bugs.css';
import '../theme/theme.css';
import ThemeToggle from '../theme/theme';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Bugs = () => {
  const [themeMode, setThemeMode] = useState('light');
  const navigate = useNavigate();

  const handleThemeToggle = () => {
    setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  useEffect(() => {
    const root = document.documentElement;
    if (themeMode === 'dark') {
      root.style.setProperty('--background-light', 'black');
      root.style.setProperty('--background-light-alpha', 'black');
      root.style.setProperty('--background-dark', 'white');
      root.style.setProperty('--background-dark-alpha', 'rgba(201, 220, 30, 0.962)');
      root.style.setProperty('--background-gradient', 'linear-gradient(to top, black, rgb(100, 100, 100))');
    } else {
      root.style.setProperty('--background-light', 'white');
      root.style.setProperty('--background-light-alpha', 'white');
      root.style.setProperty('--background-dark', 'black');
      root.style.setProperty('--background-dark-alpha', 'black');
      root.style.setProperty('--background-gradient', 'linear-gradient(to top, rgb(100, 100, 100),white)');
    }
  }, [themeMode]);

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <div className='bugs-background'>
      <div className="theme-toggle">
        <ThemeToggle onChange={handleThemeToggle} checked={themeMode === 'dark'} />
      </div>
      <div className="back-button" onClick={handleBackClick}>
        <svg viewBox="0 0 24 24">
          <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
        </svg>
      </div>
      <h1 className='bugs-title'>Bugs :</h1>
      <ul className='bugs-items'>
        <li>This is less of a bug and more of a convenience issue where the face detection will scan the full image and fail. But the cropper SHOULD detect an image however it does not currently scan. This is being actively worked on and it is recommended to manually crop images to orient the face.</li>
        <li>There is a bug in which the client will properly handle the face detection however the server will fail to process it. Currently, it will return a gray texture instead of the face.</li>
      </ul>
    </div>
  );
};

export default Bugs;
