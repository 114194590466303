import * as THREE from 'three';

class VitruvianHead {
  constructor(data){
    this.data=data;
    this.child=null;
    this.headVertexRelationships = {
      '0': 10, '1': 10, '2': 21, '3': 54, '4': 58, '5': 67, '6': 93, '7': 103, '8': 109, '9': 127,'10': 132,
      '11': 136, '12': 148, '13': 149, '14': 150, '15': 152, '16': 162, '17': 172, '18': 176,'19': 234,'20': 251,
      '21': 284, '22': 288, '23': 297, '24': 323, '25': 332, '26': 338, '27': 356,'28': 361, '29': 365,
      '30': 377, '31': 378, '32': 379, '33': 389, '34': 397, '35': 400, '36':454
    };
    this.vertices=null;
  }

  run(){
    this.child=this.data.mediaprocessing.child;
    this.vertices=this.data.mediaprocessing.vertices

    const landmarks = this.data.dataServer._extractions.head.face.cropped_landmarks_list;
    let scale;
    let totalPoints = 0;

    if (this.data.dataServer._extractions.gender.identity === 'male') {scale = 2.5;} else {scale = 2.25;}

    if (!landmarks || landmarks.length === 0) {
      console.error("No landmarks found or landmarks array is empty.");
      return null;
    }

    const averagePosition = new THREE.Vector3();
    landmarks.forEach(landmark => {
      averagePosition.add(new THREE.Vector3(landmark[0], landmark[1], landmark[2]));
      totalPoints++;
    });
    averagePosition.divideScalar(totalPoints);

    const landmarksCentered = landmarks.map(landmark => {
      return {
        x: landmark[0] - averagePosition.x,
        y: landmark[1] - averagePosition.y,
        z: landmark[2] - averagePosition.z
      };
    });

    const vitruvianLandmarks = landmarksCentered.map(landmark => {
      return {
        x: landmark.x * scale,
        y: -landmark.y * scale,
        z: -landmark.z * scale
      };
    });
    this.data.mediaprocessing.vitruvianLandmarks=vitruvianLandmarks;

    for (const [headIndex, faceIndex] of Object.entries(this.headVertexRelationships)) {
      if (vitruvianLandmarks[faceIndex]) {
        const currentPosition = new THREE.Vector3().fromBufferAttribute(this.vertices, headIndex);
        const translation = new THREE.Vector3(vitruvianLandmarks[faceIndex].x, vitruvianLandmarks[faceIndex].y, vitruvianLandmarks[faceIndex].z).sub(currentPosition);
        this.vertices.setXYZ(headIndex, currentPosition.x + translation.x, currentPosition.y + translation.y, currentPosition.z + translation.z);
      }
    }

    this.child.material.color.set(0xffffff);
    this.child.material.map = this.data.mediaprocessing.textureColorAverage;
    this.vertices.needsUpdate = true;
    this.child.material.needsUpdate = true;
  }
};

export default VitruvianHead;
