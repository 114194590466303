import './home.css';
import '../theme/theme.css';
import '../vitruvian/options/options.css';
import Data from '../data/data';
import Editor from '../vitruvian/editor/editor';
import Generator from '../vitruvian/generator/generator';
import Info from '../info/info';
import ThemeToggle from '../theme/theme';
import VitruvianMediaprocessing from '../vitruvian/mediaprocessing/mediaprocessing';
import Options from '../vitruvian/options/options';
import Zoom from '../zoom/zoom';
import React, { useState, useEffect } from 'react';

const VitruvianHome = () => {
  const [dataServer, setDataServer] = useState(null);
  const [error, setError] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [modelUrl, setModelUrl] = useState(null);
  const [modelUuid, setModelUuid] = useState(null);
  const [showEditor, setShowEditor] = useState(false);
  const [texFaceUrl, setTexFaceUrl] = useState(null);
  const [texColorAverageUrl, setTexColorAverageUrl] = useState(null);
  const [themeMode, setThemeMode] = useState('light');
  const [zoomLevel, setZoomLevel] = useState(100);

  const [options, setOptions] = useState({
    blurValue: 1,
    environmentBrightness: 0.5,
    environmentPreset: 'sunset',
    isEnvironmentTransparent: true,
    isOutfitVisible: true,
    isTurntableOn: false,
    isWireframe: false,
  });

  const handleFileChange = (file) => {
    setImageSrc(URL.createObjectURL(file));
    setTexFaceUrl(null);
    setModelUuid(null);
    setError(null);
    setShowEditor(false);
  };

  const handleThemeToggle = () => {
    setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const handleZoomChange = (event, newValue) => {
    setZoomLevel(newValue);
  };

  const currentDate = new Date().toLocaleDateString('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  }).replace(/\//g, '-');

  useEffect(() => {
    const root = document.documentElement;
    if (themeMode === 'dark') {
      root.style.setProperty('--background-light', 'black');
      root.style.setProperty('--background-dark', 'white');
      root.style.setProperty('--background-gradient-direction', 'to top');
    } else {
      root.style.setProperty('--background-light', 'white');
      root.style.setProperty('--background-dark', 'black');
      root.style.setProperty('--background-gradient-direction', 'to bottom');
    }
  }, [themeMode]);

  useEffect(() => {
    const checkModelAvailability = async () => {
      if (imageSrc && modelUuid) {
        const uuid = modelUuid.split('.')[0];
        const texFaceUrl = `https://phi-vitruvian2358.s3.us-west-2.amazonaws.com/public/dev/${currentDate}/${uuid}/tex_head.jpg`;
        const texColorAverageUrl = `https://phi-vitruvian2358.s3.us-west-2.amazonaws.com/public/dev/${currentDate}/${uuid}/tex_color_average.jpg`;

        try {
          const urls = [texFaceUrl, texColorAverageUrl];
          const responses = await Promise.all(urls.map(url => fetch(url)));
          if (responses.every(response => response.ok)) {
            setTexFaceUrl(texFaceUrl);
            setTexColorAverageUrl(texColorAverageUrl);
            const dataServerInstance = new Data(
              currentDate,
              imageSrc,
              modelUuid,
              setDataServer,
              setError,
              setModelUrl,
              setShowEditor
            );
            data.dataServer = dataServerInstance.vitruvianDataServer();
          } else {
            setError('The GLB file is not available yet. Please wait.');
            setTimeout(checkModelAvailability, 1500);
          }
        } catch (error) {
          console.log('An error occurred while checking model availability:', error);
          setError('An error occurred while checking model availability. Retrying...');
          setTimeout(checkModelAvailability, 1500);
        }
      }
    };

    checkModelAvailability();
  }, [currentDate, imageSrc, modelUuid]);

  let data = {
    currentDate: currentDate,
    dataServer: dataServer,
    mediaprocessing: null,
    modelUrl: modelUrl,
    options: options,
    texFaceUrl: texFaceUrl,
    texColorAverageUrl: texColorAverageUrl,
    uuid: modelUuid,
  };

  let mediaprocessing = new VitruvianMediaprocessing(data);
  data.mediaprocessing = mediaprocessing;

  return (
    <div className="themed-home">
      <div className="theme-toggle">
        <ThemeToggle onChange={handleThemeToggle} checked={themeMode === 'dark'} />
      </div>
      <div className="info">
        <Info />
      </div>
      <Zoom className='zoom' themeMode={themeMode} zoomLevel={zoomLevel} handleZoomChange={handleZoomChange} />
      <h1 className="title">VITRUVIAN</h1>
      <div className="container" style={{ zoom: `${zoomLevel}%` }}>
        <Generator data={data} onFileChange={handleFileChange} onUUIDGenerated={(uuid) => { setModelUuid(uuid); }} wait={showEditor} />
        {showEditor && (
          <div className="editor-container" style={{ zoom: `${zoomLevel}%` }}>
            {modelUrl ? (
              <Editor data={data} />
            ) : (
              <p>{error || 'Waiting for the GLB file to be available...'}</p>
            )}
          </div>
        )}
      </div>
      {showEditor && (
        <div className="options-container">
          <Options options={options} optionsUpdate={setOptions} themeMode={themeMode} />
        </div>
      )}
    </div>
  );
};

export default VitruvianHome;
