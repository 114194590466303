import './generate.css';
import '../../../theme/theme.css';
import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Storage } from 'aws-amplify';

const Generate = ({ data, landmarks, onUUIDGenerated, selectedFile, croppedAreaPixels, wait }) => {
  const [uuid, setUuid] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (wait) {
      setLoading(false);
    }
  }, [wait]);

  const cropImage = (imageSrc, cropPixels) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = cropPixels.width;
        canvas.height = cropPixels.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
          image,
          cropPixels.x,
          cropPixels.y,
          cropPixels.width,
          cropPixels.height,
          0,
          0,
          cropPixels.width,
          cropPixels.height
        );
        canvas.toBlob((blob) => {
          const croppedFile = new File([blob], 'cropped-image.jpg', { type: 'image/jpeg' });
          resolve(croppedFile);
        }, 'image/jpeg');
      };
      image.onerror = reject;
    });
  };

  const convertToJpg = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            const jpgFile = new File([blob], 'converted-image.jpg', { type: 'image/jpeg' });
            resolve(jpgFile);
          }, 'image/jpeg');
        };
        img.src = event.target.result;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleUpload = async () => {
    if (selectedFile) {
      try {
        setLoading(true);
        const generatedUuid = uuidv4();
        setUuid(generatedUuid);

        const dataDest = `dev/${data.currentDate}/${generatedUuid}/data_client.json`;

        let dataClient = {};

        dataClient.style = {
          extractions: {
            head: {
              face: {
                landmarks: landmarks,
              },
            },
          },
        };

        let processedFile = selectedFile;

        if (croppedAreaPixels) {
          const imageSrc = URL.createObjectURL(selectedFile);
          processedFile = await cropImage(imageSrc, croppedAreaPixels);
        }

        const jpgFile = await convertToJpg(processedFile);

        await Promise.all([
          Storage.put(
            `dev/${data.currentDate}/${generatedUuid}/img.jpg`,
            jpgFile,
            { level: 'public', contentType: jpgFile.type, region: 'us-west-2' }
          ),
          Storage.put(
            dataDest,
            JSON.stringify(dataClient),
            { level: 'public', contentType: 'application/json', region: 'us-west-2' }
          )
        ]);

        console.log('File uploaded successfully!');
        onUUIDGenerated(generatedUuid);
      } catch (error) {
        console.error('An error occurred during file upload:', error);
      }
    }
  };

  const isDisabled = Boolean(loading || wait);

  return (
    <div>
      <button 
        className={`generate-button ${loading ? 'loading' : ''}`} 
        onClick={handleUpload} 
        disabled={isDisabled}
      >
        {loading ? 'Generating...' : 'Generate'}
      </button>
      <div className="themed-generate-container">
        {uuid && <p>ID: {uuid}</p>}
      </div>
    </div>
  );
};

export default Generate;
