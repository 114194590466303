class VitruvianUVs{
  constructor(data){
      this.data=data;
  }

  run(){
    const uvs=this.data.mediaprocessing.uvs;
    // Calculate UVs based on landmarks
    if (uvs && uvs.count > 0) {
      let maxU = -Infinity, maxV = -Infinity;
      let minU = Infinity, minV = Infinity;
      let minY = Infinity, maxY = -Infinity;

      // Compute min and max Y values
      this.data.mediaprocessing.vitruvianLandmarks.forEach((landmark) => {
        const v = landmark.y + 0.5;
        if (v < minY) minY = v;
        if (v > maxY) maxY = v;
      });

      // Compute min and max V values
      this.data.mediaprocessing.vitruvianLandmarks.forEach((landmark) => {
        const u = landmark.x + 0.5;
        if (u < minU) minU = u;
        if (u > maxU) maxU = u;
      });

      const aspectRatio = (maxY - minY);

      // Normalize UVs to fit within the UV space while maintaining aspect ratio
      this.data.mediaprocessing.vitruvianLandmarks.forEach((landmark, index) => {
        if (index < uvs.count) {
          const u = landmark.x / aspectRatio + 0.5;
          const v = (landmark.y + 0.5 - minY) / (maxY - minY); // normalized
          uvs.setXY(index, u, v);
        }
      });

      uvs.needsUpdate = true;
    }
  }
}

export default VitruvianUVs;
