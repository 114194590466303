import './generator.css';
import '../../theme/theme.css';
import DragDrop from './dragdrop/drag-drop';
import Generate from './generate/generate';
import GeneratorCropper from './cropper/cropper';
import generateTextToImage from '../mediaprocessing/diffusion/text-to-img';
import React, { useState, useEffect, useRef } from 'react';

const Generator = ({ data, onFileChange, onUUIDGenerated ,wait}) => {
  const [currentImage, setCurrentImage] = useState(null);
  const [faceDetected, setFaceDetected] = useState(false);
  const [faceLandmarks, setFaceLandmarks] = useState(null);
  const [loading, setLoading] = useState(false);
  const [prompt, setPromptText] = useState('');
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const imageWrapperRef = useRef(null);

  const convertToJpg = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            const jpgFile = new File([blob], 'converted-image.jpg', { type: 'image/jpeg' });
            resolve(jpgFile);
          }, 'image/jpeg');
        };
        img.src = event.target.result;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const processImage = async (file) => {
    const jpgFile = await convertToJpg(file);
    data.mediaprocessing.image = jpgFile;
    const { status, landmarks } = await data.mediaprocessing.vitruvianLandmraksInit();
    setFaceDetected(status && landmarks && landmarks.faceLandmarks && landmarks.faceLandmarks.length > 0);
    setFaceLandmarks(landmarks);
    onFileChange(jpgFile);
  };

  const handleFileChange = async (file) => {
    setLoading(true);
    const jpgFile = await convertToJpg(file);
    setCurrentImage(jpgFile);
    await processImage(jpgFile);
    setLoading(false);
  };

  const handleGenerateTextToImage = async () => {
    setLoading(true);
    const generatedBlob = await generateTextToImage(prompt);
    const jpgFile = await convertToJpg(generatedBlob);
    setCurrentImage(jpgFile);
    await processImage(jpgFile);
    setLoading(false);
  };

  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  useEffect(() => {
    if (imageWrapperRef.current && currentImage) {
      imageWrapperRef.current.scrollTop = imageWrapperRef.current.scrollHeight / 2 - imageWrapperRef.current.clientHeight / 2;
    }
  }, [currentImage]);

  return (
    <div className="generator-container">
      <div className='input-container'>
        <div className='diffusion-container'>
          <input type="text" value={prompt} onChange={(e) => setPromptText(e.target.value)} placeholder="Enter prompt text" />
          <button onClick={handleGenerateTextToImage} disabled={loading || prompt.trim() === ''}>Generate</button>
        </div>
        <div>or</div>
        <div><DragDrop onImageDrop={handleFileChange} /></div>
      </div>

      {currentImage && (
        <div className="image-preview">
          <div className="image-wrapper" ref={imageWrapperRef}>
            <img 
              alt="Current Image"
              className="preview-image"
              src={URL.createObjectURL(currentImage)} 
            />
            <div className='cropper'>
              <GeneratorCropper
                imageSrc={URL.createObjectURL(currentImage)}
                onCropComplete={handleCropComplete}
              />
            </div>
          </div>
        </div>
      )}

      <div className="themed-generator-confirmation">
        {currentImage ? (faceDetected ? <p>Face Detected!</p> : <p>No Face Detected!</p>) : null}
      </div>

      {currentImage && faceDetected && faceLandmarks ? (
        <div className="generate-wrapper">
          <Generate 
            data={data} 
            landmarks={faceLandmarks} 
            onUUIDGenerated={onUUIDGenerated} 
            selectedFile={currentImage} 
            croppedAreaPixels={croppedAreaPixels} 
            wait={wait}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Generator;
